<template>
<section class="w-screen h-screen absolute"


>

  <div id="toggle"
       @click="openMenu"
       :class="{ open: open }"
  ><span class="flex"></span></div>
  <div id="menu"
       :class="{ opened: open }"

  ><span id="title">Menu</span>
    <ul>
      <li><a href="#"><span>Home</span></a></li>
      <li><a href="#"><span>Forum</span></a></li>
      <li><a href="#"><span>Downloads</span></a></li>
    </ul>
  </div>
  <div id="wrapper">
    <div id="logo"><span>Mobile Navigation Menu</span></div>
    <section>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Blanditiis sunt mollitia molestiae maxime, voluptatibus, voluptas placeat minima natus temporibus culpa repudiandae! Nam porro molestiae possimus, laboriosam, vero tenetur consequatur voluptatibus?</section>
  </div>
</section>

</template>

<script>
export default {
  name: "HamburgerMenuNr2",


  data() {

    return  {

      open : false,

    }


  },


methods: {

    openMenu(){

       this.open = !this.open

    }

}

}
</script>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css?family=Quicksand');

@mixin transition($duration, $timing-func) {
  -webkit-transition: $duration $timing-func all;
  -moz-transition: $duration $timing-func all;
  transition: $duration $timing-func all;
}

@mixin scale($size) {
  -webkit-transform: scale($size);
  -moz-transform: scale($size);
  transform: scale($size);
}

@mixin rotate($deg) {
  -webkit-transform: rotate($deg);
  -moz-transform: rotate($deg);
  transform: rotate($deg);
}

$bg: rgb(40,110,250);
$menu-bg: #fff;
$menu-hover: rgba(40,110,250,0.25);
$size: 5em;
$time: 350ms;
$degree: 135deg;
$timing: ease;

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

section {
  background: $bg;
  color: #eee;
  font-family: 'Quicksand', sans-serif;
}

#toggle {
  position: fixed;
  z-index: 3;
  width: $size / 1.75;
  height: $size / 1.75;
  top: 0;
  left: 0;
  margin: 15px 0 0 40px;
  cursor: pointer;

  span {
    position: absolute;
    width: 100%;
    height: $size / 25;
    margin: $size / 4 0 0 0;
    background: #eee;
    @include transition($time, $timing);

    &:before,
    &:after {
      content: " ";
      position: absolute;
      width: 100%;
      height: $size / 25;
      background: #eee;
      @include transition($time, $timing);
    }

    &:before {
      margin: -$size / 5 0 0 0;
    }

    &:after {
      margin: $size / 5 0 0 0;
    }
  }

  &.open {
    span {
      background-color: transparent;

      &:before,
      &:after {
        margin: 0;
        background: $bg;
      }

      &:before {
        @include rotate($degree);
      }

      &:after {
        @include rotate(-$degree);
      }
    }
  }
}

#menu {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  z-index: 2;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  text-align: center;
  background: $menu-bg;
  @include scale(1.5);
  @include transition($time, $timing);

  &.opened {
    visibility: visible;
    opacity: 1;
    @include scale(1);
    @include transition($time, $timing);
  }

  span {
    &#title {
      display: block;
      line-height: $size / 5;
      margin: 0 0 $size / 10 0;
      font-size: $size;
      color: $bg;
    }
  }

  ul {
    list-style: none;
    margin: 0 auto;

    li {
      display: block;
      width: 90%;
      height: 65px;
      margin: 0 auto $size / 10 auto;

      &:after {
        content: " ";
        position: absolute;
        width: 0%;
        height: 65px;
        margin: -65px 0 0 5%;
        left: 0;
        background: $bg;
        border-radius: $size / 20;
        @include transition($time, $timing);
      }

      &:hover {
        &:after {
          width: 90%;
        }
      }

      a {
        position: relative;
        z-index: 4;
        display: block;
        width: 100%;
        height: 65px;
        line-height: 65px;
        margin: 0 auto;
        color: $bg;
        text-decoration: none;
        border: 1px solid #ddd;
        border-radius: $size / 25;
        @include transition($time, $timing);

        &:hover {
          color: #eee;
          border-color: $bg;
        }

        span {
          font-size: $size / 3;
        }

        i {
          position: absolute;
          padding: 0 $size / 4 0 0;
          font-size: $size / 2.5;
        }
      }
    }
  }
}

#wrapper {
  width: 85%;
  margin: 0 auto;

  #logo {
    display: block;
    width: 100%;
    margin: 40px auto;
    text-align: center;

    span {
      font-size: $size / 1.5;
    }
  }

  section {
    font-size: $size / 4.5;
  }
}

</style>